.header {
  width: 100%;
  padding: 18px 0;
  background-color: #f9fafb;
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.header__left {
  display: flex;
  align-items: center;
  gap: 15px;
}

.header__left-logo-first {
  width: 107px;
  height: auto;
}

.header__left-divider {
  height: 20px;
  width: 0.8px;
  background: #000;
  opacity: 0.3;
  border-radius: 1px;
}

.header__left-logo-second {
  width: 151px;
  height: auto;
}

.header__right {
  text-align: right;
  color: rgba(0, 0, 0, 0.4);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.header__email {
  text-decoration: none;
  color: #3b82f6;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

@media (max-width: 1024px) {
  .header__inner {
    width: 100%;
  }
  .header__left {
    justify-content: space-between;
    width: 100%;
  }
  .header__right {
    display: none;
  }
}
