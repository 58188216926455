.download-screen__input {
    /* display: inline-flex;
    flex-wrap: nowrap;
    padding: 12px 5px;
    width: 167px;
    min-width: 167px;
    max-width: 530px;
    border: none;
    border-bottom: 1px solid #6B7280;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #111827;
    background-color: #F9FAFB; */
}

.download-screen__input:focus {
    
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
     box-shadow: 0 0 0 0px #F9FAFB inset !important;
}