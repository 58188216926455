.main {
  padding: 84px 0;
  padding-bottom: 100px;
  background: url("../../images/mainBg.svg") center/cover no-repeat;
}

.main__inner {
  display: flex;
  gap: 20px;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.main__title {
  margin: 0;
  color: #f9fafb;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 500px;

  margin-bottom: 16px;
}

.main__subtitle {
  color: rgba(249, 250, 251, 0.7);
  font-family: "Fira Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  max-width: 505px;

  margin-bottom: 34px;
}

.main__box {
  padding: 20px;
  border-radius: 5px;
  background: #fff;
}
.main__box-title {
  margin: 0;
  color: #333;
  font-family: "Fira Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 12px;
}

.main .main__box-input {
  font-family: "Roboto Mono", monospace;
  width: 292px !important;
  height: 44px;
  padding: 0 12px;

  border-radius: 6px;
  background: #efefef;
  border: none;
  outline: none;
  font-size: 14px;
  letter-spacing: 1.4px;
  caret-color: #009bd6;
}

.main__container-center {
}

.main__form {
}

.main__btn {
  padding: 10px 44px;
  cursor: pointer;
  border-radius: 4px;
  background: #009ad9;

  color: #fff;
  font-family: "Fira Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none;
  transition: all 0.3s ease;
}

.main__btn:hover {
  background-color: #60a5fa;
}

.main__instructions {
  margin-top: 22px;
  display: flex;
  gap: 32px;
  align-items: center;
}
.main__instruction {
  display: flex;
  gap: 8px;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  text-decoration-line: underline;
}

.main__inpur-field {
  position: relative;
  margin-right: 8px;
}

.main__input-error {
  width: 100%;
  position: absolute;
  margin-top: 49px;
  margin-left: -100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ff136b;
  text-align: left;
}

.main__input-error .main__box {
  padding-bottom: 100px;
}

.mb-err {
  padding-bottom: 34px;
}

.mb-err .main__box-input {
  border-radius: 6px;
  background: rgba(255, 59, 61, 0.1);
}

.main__right {
  max-width: 42vw;
}

@media (max-width: 1024px) {
  .main {
    padding: 32px 0;
    padding-bottom: 54px;
    background: url("../../images/mainBg.svg") top/cover no-repeat;
    background-position: left;
  }
  .main__title {
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-bottom: 12px;
  }
  .main__subtitle {
    margin: 0;
    color: rgba(249, 250, 251, 0.8);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    margin-bottom: 32px;
  }
  .main__right {
    display: none;
  }
  .main__box {
    padding: 16px;
  }
  .main__box-title {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 12px;
  }
  .main .main__inpur-field {
    width: 100% !important;
  }
  .main .main__box-input {
    width: 100% !important;
    margin-bottom: 18px;
    font-size: 14px;
  }
  .main__btn {
    width: 100%;
    padding: 12px 24px;
  }
  .main__inpur-field {
    margin-right: 0;
  }
  .mb-err .main__box-input {
    margin-bottom: 38px !important;
  }
  .main__instructions {
    flex-wrap: wrap;
    gap: 16px;
  }
}

@media (max-width: 1024px) {
  .main .main__box-input {
    text-align: center;
    letter-spacing: 3px;
  }
  .main__input-error {
    text-align: center;
  }
}

@media (max-width: 420px) {
  .main .main__box-input {
    letter-spacing: 1.4px;
  }
}

@media (max-width: 380px) {
  .main .main__box-input {
    letter-spacing: 1px;
  }
}

@media (max-width: 360px) {
  .main .main__box-input {
    letter-spacing: 0.7px;
  }
}
