@import url(./vendor/normalize.css);
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500&display=swap');

html {
  font-family: 'Fira Sans', sans-serif;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #F9FAFB;
  color: #111827;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
