.func {
  border-radius: 20px 20px 0px 0px;
  background: #f5faff;
  padding-top: 40px;
  padding-bottom: 44px;

  position: relative;
  top: -32px;
}

.func__inner {
  display: flex;
  flex-direction: column;
}

.func__title {
  margin: 0;
  color: #333;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-bottom: 40px;
}

.func__cards {
  display: flex;
  gap: 24px;
  margin-bottom: 64px;
}

.func__card {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 34px 0px #deecf9;
}

.func__card-icon {
  width: 56px;
  height: 56px;

  margin-bottom: 20px;
}

.func__card-title {
  margin: 0;
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-bottom: 12px;
}

.func__card-subtitle {
  margin: 0;
  color: #5d5d5d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.func__btm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.func__btm-left {
  flex: 1 1 auto;
  max-width: 754px;
  margin: 0;

  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.func__btm-right {
  display: flex;
  gap: 20px;
  max-width: 560px;
}

.func__btm-right-box {
  flex: 1 1 50%;
  max-width: 100%;

  color: rgba(0, 0, 0, 0.4);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.func__btm-right-box-email {
  text-decoration: none;
  color: #009bd6;
  margin-left: 6px;
  font-weight: 600;
}


@media (max-width: 1024px) {
  .func {
    padding: 28px 0;
    padding-bottom: 34px;
  }
  .func__title {
    font-size: 28px;
    margin-bottom: 24px;
  }
  .func__cards {
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
  .func__card {
    width: 100%;
  }
  .func__card-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
  }
  .func__card-title {
    color: #333;
    font-size: 20px;
    margin-bottom: 12px;
  }
  .func__card-subtitle {
    color: #5d5d5d;
    font-size: 14px;
  }

  .func__btm {
    flex-wrap: wrap;
    gap: 16px;
  }
  .func__btm-left {
    font-size: 18px;
  }
  .func__btm-right {
    max-width: 100%;
    flex-wrap: wrap;
    gap: 16px;
  }
  .func__btm-right-box {
    max-width: 100%;

    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
  }
}
